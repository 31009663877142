import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { RiImageEditFill } from "react-icons/ri";

const EditButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        className="flex items-center space-x-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none"
      >
        <RiImageEditFill size={20} />
        <span>Edit</span>
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-violet-500 to-pink-500 bg-opacity-75 z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white rounded-lg shadow-lg shadow-purple-800 p-6 relative w-11/12 max-w-md animate-rebound"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <FaTimes size={20} />
            </button>

            <h2 className="text-xl font-bold mb-4 text-center">This feature is on the way!</h2>


            <video
              className="w-full h-auto mb-4 rounded-md"
              src="/EditImage.mp4" 
              autoPlay
              loop
              muted
            />


            <p className="text-center mb-4">
              If you want to get notified when this feature launches and other advanced features, sign up today!
            </p>


            <button
              className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white py-2 rounded-md hover:from-pink-700 hover:to-purple-800 transition-colors"
              onClick={() => {
                console.log('Feedback and subscription triggered');
                closeModal();
              }}
            >
              Sign me up
            </button>
          </div>
        </div>
      )}


      <style jsx>{`
        @keyframes rebound {
          0% {
            transform: scale(100%);
            opacity: 0;
          }
          50% {
            transform: scale(150%);
            opacity: 1;
          }
          100% {
            transform: scale(100%);
            opacity: 1;
          }
        }
        .animate-rebound {
          animation: rebound 0.5s ease-out;
        }
      `}</style>
    </>
  );
};

export default EditButton;