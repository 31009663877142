/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate} from 'react-router-dom';
import './HomePage.css';

export default function HomePage({setLoggedIn}) {
  const [images1, setImages1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const [playState, setPlayState] = useState("paused");
  const [isVisible, setIsVisible] = useState(false);
  const [value, setValue] = useState(10); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      // Generate unique URLs for imagesToLoad1
      const imageUrls1 = Array.from(
        { length: 10 },
        (_, i) => `https://picsum.photos/600/800?random=${i + 1}`
      );
      
      const imageUrls2 = Array.from(
        { length: 10 },
        (_, i) => `https://picsum.photos/600/800?random=${i + 10}`
      );
      
      const imageUrls3 = Array.from(
        { length: 10 },
        (_, i) => `https://picsum.photos/600/800?random=${i + 19}`
      );
      
      const imageUrls4 = Array.from(
        { length: 10 },
        (_, i) => `https://picsum.photos/600/800?random=${i + 23}`
      );
      
      const imagesToLoad1 = [...imageUrls1, ...imageUrls2, ...imageUrls1,...imageUrls2, ...imageUrls1, ...imageUrls2]; 
      const imagesToLoad2 = [...imageUrls2, ...imageUrls3, ...imageUrls4,...imageUrls1, ...imageUrls2, ...imageUrls4]; 

      const imagePromises1 = imagesToLoad1.map(
        (url) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          })
      );

      const imagePromises2 = imagesToLoad2.map(
        (url) =>
          new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve;
            img.onerror = reject;
          })
      );

      try {
        await Promise.all([...imagePromises1, ...imagePromises2]);
        setImages1(imagesToLoad1);
        setImages2(imagesToLoad2);
      } catch (error) {
        console.error("Error loading images", error);
      }
    };

    fetchImages();

    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateStyles = (index) => {
    const width = viewportWidth < 1024 ? 260 : 540;
    const height = viewportWidth < 1024 ? 405 : 810;
    const columns = 10; 
    
    const top = Math.floor(index / columns) * height; 
    const left = (index % columns) * width; 
  
    return {
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
    };
  };

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      setTimeout(() => {
        const homeDiv = document.getElementById("home");
        if (homeDiv) {
          homeDiv.style.maskSize = "300%";
        }
      }, 3000); // Delay to ensure page load
    }
  }, []);



  const handleMouseEnter = () => {
    setIsHovered(true);
    setPlayState("running");
  };
  
  // Handle Mouse Leave with Delay
  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsHovered(false);
      setPlayState("paused");
    }, 150); // Delay to allow animation to finish
  };

  // handles the first load
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // to generate that random sweet number on the flying cards, baby! yeah!
  useEffect(() => {
    const randomValue = Math.floor(Math.random() * (25 - 5 + 1)) + 5; 
    setValue(randomValue);
  }, []);
  

  const handleSuccess = async (response) => {
    const authenticationResponse = await fetch(`${window.API_URL}/authenticate`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(response),
    });
    
    const res = await authenticationResponse.json();

    console.log(">>> res from server", res)
    if(res?.authenticated){
      const profilePic = res.currentUser.picture || "https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg";
      sessionStorage.setItem('currentUserPic', profilePic);
      sessionStorage.setItem('token', res.token);
      sessionStorage.setItem('currentUser', JSON.stringify(res.currentUser));
      setLoggedIn(true); 
      navigate("/create");
    }
    else{
      // show errorText to the client
    }
  }

  const handleError = (response) => {
    console.log(">>> error", response)
  }

  return (
    <div className="overflow-hidden">
        <div className={`relative h-screen overflow-hidden transition-all duration-1000 ease-in-out ${isVisible ? 'opacity-100  scale-100' : 'opacity-0 scale-105'}`}>
      <div
        id="home"
        style={{
          maskSize: isHovered ? "300%" : "0%", 
        }}
        className="transition-mask-size duration-1000"
      >
        <div className="absolute inset-0 overflow-hidden z-10 bg-black">
          <div className="honeycomb-animation scale-75 ml-[-170px]">
            {/* Images 1 are the background images */}
            {images1.map((src, index) => (
              <div
                key={`image1-${index}`} 
                className="honeycomb-cell"
                style={{
                    ...calculateStyles(index)
                  }}
              >
                <img
                  src={src}
                  alt={`background1 ${index}`}
                  className="rounded-lg shadow-xl"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="absolute inset-0 overflow-hidden z-20">
          <div className="honeycomb-animation">
          {images2.map((src, index) => {
    const randomValue = Math.floor(Math.random() * (25 - 5 + 1)) + 5; // Random value between 5 and 25

    const getBackgroundColor = (value) => {
      if (value >= 20) return 'bg-green-500 text-white';
      if (value <= 10) return 'bg-white text-black';
      return 'bg-yellow-200 text-black'; 
    };

    return (
      <div
        key={`image2-${index}`} 
        className="honeycomb-cell-two"
        style={{
            ...calculateStyles(index)
        }}
      >
        <div className="rotate-12 z-50 absolute left-[20%] mt-4">
          <div className=" font-medium block mb-3">
            <span className={`px-2 pt-0 pb-1 rounded-md text-xl lg:text-4xl ${getBackgroundColor(randomValue)}`}>
              {randomValue}X
            </span>
          </div>
          <span className="px-2 py-1 rounded-md text-xs lg:text-sm bg-white font-semibold uppercase inline-block">Higher engagement rate vs. traditional ads</span>
        </div>
        <img
          src={src}
          alt={`background2 ${index}`}
          className="rounded-lg shadow-2xl shadow-black"
        />
      </div>
    );
})}

          </div>
          
        </div>
      </div>
      <div className="absolute inset-0 flex items-center justify-center z-30 pointer-events-none">
        <div className="w-full h-full bg-blue-500 absolute opacity-30 lg:bg-pink-950"></div>
        <div className="text-center max-w-[90%] lg:max-w-[70%] relative pointer-events-auto">
          <h1 className="text-white text-4xl lg:text-6xl font-bold mb-8">
            Generate high converting images for your LinkedIn ads with AI
          </h1>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <button
              id="show-me"
              className="bg-purple-600 mx-auto w-48 md:w-auto md:mx-0 hover:bg-purple-700 border border-purple-800 shadow-md text-white font-bold py-2 px-4 rounded"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Show me
            </button>
            <div className="mx-auto md:mx-0">
            <GoogleLogin
                onSuccess={credentialResponse => {
                  handleSuccess(credentialResponse);
                }}
                onError={() => {
                  handleError('Login Failed');
                }} 
                useOneTap
                />
            </div>
          </div>
          <p className="block w-full text-center mt-1.5 text-white font-semibold">
            No credit card required
          </p>
        </div>
      </div>
    </div>
    </div>
  );

}
